import { relayStylePagination } from '@apollo/client/utilities'

// Note: you must merge fields for the full object merge to have an "existing" object set.
export const typePolicies = {
    Query: {
        fields: {
            employees: relayStylePagination(['publicId', 'sort']),
            groups: relayStylePagination(['filters', 'sort']),
        },
    },
    HolidayCalendarType: {
        keyFields: ['wdRefId'],
    },
    EmployeeType: {
        fields: {
            timeOffEntries: relayStylePagination(['filters', 'sort']),
        },
    },
    TimeOffEntryType: {
        keyFields: ['timeOffEntryId'],
    },
}

import * as OWUtils from '~/utils/OWUtils'
import * as EmployeeUtils from '~/utils/EmployeeUtils'
import { Employee } from '~/app/types'

let _indexInstance: EmployeeSearchIndex | null = null

type Doc = { token: string; field: string }

export class EmployeeSearchIndex {
    private docs: Record<string, { employee: object; docs: Doc[] }>

    constructor() {
        this.docs = {}
    }

    static fields = ['first_name', 'last_name', 'nick_name', 'title']

    static getInstance() {
        if (!_indexInstance) {
            _indexInstance = new EmployeeSearchIndex()
        }
        return _indexInstance
    }

    getTokens(s) {
        return s
            .toLocaleLowerCase()
            .split(/\s+/g)
            .filter(Boolean)
            .map(s => OWUtils.removeAccents(s))
    }

    getDocs(e) {
        const docs: Doc[] = []
        EmployeeSearchIndex.fields.forEach(f => {
            const tokens = this.getTokens(e[f] || '').map(t => ({
                token: t,
                field: f,
            }))
            Array.prototype.push.apply(docs, tokens)
        })
        return docs.sort(({ token: token1 }, { token: token2 }) => token1.length - token2.length)
    }

    insertEmployee(e) {
        this.docs[e.id] = { employee: e, docs: this.getDocs(e) }
    }

    removeEmployee(eId) {
        delete this.docs[eId]
    }

    clear() {
        this.docs = {}
    }

    search(query): Employee[] {
        const queryTokens = this.getTokens(query || '')
        const qLen = queryTokens.length
        return (
            Object.values(this.docs)
                .map(e => {
                    let exactC = 0
                    let fail = false
                    for (let i = 0; i < queryTokens.length && !fail; i++) {
                        const qt = queryTokens[i]
                        const d = e.docs.find(({ token }) => token.startsWith(qt))
                        if (d) {
                            if (d.token === qt) {
                                exactC++
                            }
                        } else {
                            fail = true
                        }
                    }
                    return { doc: e, exact: exactC === qLen, fail }
                })
                // Filter
                .filter(({ fail }) => !fail)
                // Sort
                .sort((a, b) => {
                    if (a.exact && !b.exact) {
                        return -1
                    } else if (!a.exact && b.exact) {
                        return 1
                    } else {
                        return EmployeeUtils.employeeSorter(a.doc.employee, b.doc.employee)
                    }
                })
                // Convert to employees
                .map(({ doc }) => doc.employee as Employee)
        )
    }
}
